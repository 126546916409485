import React from "react";
import { useLocation } from "react-router-dom";
import Notification from "./components/Notification/Notification.jsx";
import Option from "./components/Option/Option.jsx";
import VideoPlayer from "./components/VideoPlayer/VideoPlayer.jsx";

const Calling = () => {
  const location = useLocation();
  const data = location.state;
  return (
    <div className="my-[5rem]">
      <VideoPlayer />

      <Option props={data}>
      </Option>
      <Notification />
    </div>
  );
};

export default Calling;
