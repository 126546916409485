import React from "react"
import {useNavigate} from "react-router-dom";
import {Nav} from "../components";
import {useAppContext} from "../context/useContext";
import bgImg from '@/assets/images/bg.png'
import homeImg from '@/assets/images/img-home.png'

const Home = () => {
    const navigate = useNavigate();
    const {dark} = useAppContext();

    return (
        <div>
            <Nav />
            <div className='w-screen h-screen '>
                {dark && (
                    <div
                        style={{backgroundImage: `url(${bgImg})`}}
                        className='fixed w-full h-full bottom-0 left-0 opacity-70 wave object-contain'
                    />
                )}

                <img
                    src={homeImg}
                    alt='rocket'
                    className='absolute right-0 top-0 h-full w-auto object-contain wave '
                />

                <div className='top-[13vh] md:top-[15vh] left-10 text-[40px] sm:text-[60px] md:text-[80px] font-semibold z-10 absolute text-[#210028] dark:text-sky-300 raleway '>
                    HUMG e-NET
                    <div className='text-[25px] sm:text-[35px] md:text-[40px] text-pink-600 font-light raleway-light '>
                        become truely HUMG-er!
                    </div>
                </div>
                <div className='absolute bottom-16 left-10 md:w-[30%] pr-5 md:pr-0'>
                    <div className='font-bold text-xl sm:text-2xl md:text-3xl md:my-3 '>
                        Kết nối
                    </div>
                    <div className='text-[13px] sm:text-base md:text-[18px] '>
                        Kết nối với cộng đồng sinh viên HUMG trên HUMG eNet để cùng nhau xây dụng mái nhà chung HUMG ngày càng lớn mạnh hơn!
                    </div>
                    <div className='flex gap-x-3 items-center justify-start mt-6 sm:mt-8 md:mt-10 '>
                        <button
                            className='btn-home boxed'
                            onClick={() => {
                                navigate("/login");
                            }}>
                            Đăng nhập
                        </button>
                        <button
                            className='btn-home boxed'
                            onClick={() => {
                                navigate("/register");
                            }}>
                            Đăng ký
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
