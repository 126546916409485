import React from "react";
import { useAppContext } from "../context/useContext";
import { Navigate } from "react-router-dom";

const ProtectedLayout = ({ children }) => {
  const { user } = useAppContext();
  if (!user || user == undefined) {
    localStorage.clear()
    return <Navigate to="/login" />;
  }
  return children;
};

export default ProtectedLayout;
