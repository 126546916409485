import React, { useState } from 'react'
import { useAppContext } from "../../context/useContext";
import { Box, Button, Modal, Typography } from '@mui/material';
import { useEffect } from 'react';
import { toast } from "react-toastify";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '4px'
};
const GroupInfo = ({ dataInfo }) => {
    const { dark, autoFetch } = useAppContext();
    const [open, setOpen] = useState(false);
    const [openListUser, setOpenListUser] = useState(false);
    const [listMember, setListMember] = useState([])
    const handleOpen = () => setOpen(true);
    const handleClose = () => { setOpen(false); setOpenListUser(false) };

    const handleAddUser = async (id) => {
        try {
            const { data } = await autoFetch.post(
                `/api/group/approve`, {
                id,
                groupId: dataInfo._id
            });
            console.log(data)
        } catch (error) {
            console.log(error);
        }
        return;
    };

    useEffect(() => {

    }, [dataInfo])


    const handleReject = async (id) => {
        try {
            const { data } = await autoFetch.post(
                `/api/group/remove`, {
                id,
                groupId: dataInfo._id
            });
            console.log(data)
            dataInfo?.requests.splice(dataInfo?.requests.findIndex(e => e._id === id), 1)
            toast.success("Success!");
            setOpen(false)
        } catch (error) {
            console.log(error);
            toast.error("Somethings wrong");
            setOpen(false)
        }
        return;
    };

    const handleOpenListUser = async (id) => {
        try {
            setOpenListUser(true)
            const { data } = await autoFetch.get(
                `/api/group/members?groupId=${dataInfo._id}`);
            setListMember(data.members);
        } catch (error) {
            console.log(error);
        }
    };

    const handleRemoveUser = async (id) => {
        try {
            setOpenListUser(true)
            const { data } = await autoFetch.post(
                `/api/group/kick`, {
                userId: id,
                groupId: dataInfo._id
            });
            setListMember(data.members);
            setOpenListUser(false)
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            {dataInfo && <div className={`dark:bg-[#242526] bg-white mb-5 pt-3 rounded-lg px-2 md:px-4 ${!dark ? "shadow-post" : ""
                } `}>
                <div className='text-[40px] sm:text-50px md:text-[24px] font-bold w-full flex justify-center leading-[48px]'>{dataInfo.name}</div>
                <div className='text-[40px] sm:text-50px md:text-[16px] italic w-full flex justify-center leading-[36px]'>{dataInfo.about}</div>
                <div className='text-[40px] sm:text-50px md:text-[12px] italic w-full flex justify-center leading-[24px]'>{dataInfo.member ? dataInfo?.member.length + 1 : 1} thành viên
                    {dataInfo?.isAdmin && ` ( ${dataInfo.requests && dataInfo?.requests.length} yêu cầu tham gia )`}</div>
            </div>}
            <div className='flex justify-between'>
                {dataInfo?.isAdmin && dataInfo?.requests.length > 0 && <Button onClick={handleOpen}>Duyệt yêu cầu tham gia</Button>}
                <Button onClick={() => { handleOpenListUser(dataInfo._id) }}>Danh sách thành viên</Button>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Danh sách yêu cầu
                    </Typography>
                    {dataInfo?.requests && dataInfo?.requests.map(req =>
                        <Box>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                {req?.name}
                            </Typography>
                            <Button onClick={() => handleAddUser(req?._id)}>Đồng ý</Button>
                            <Button onClick={() => handleReject(req?._id)}>Từ chối</Button>
                        </Box>
                    )}
                </Box>
            </Modal>
            <Modal
                open={openListUser}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Danh sách yêu cầu
                    </Typography>
                    {listMember && listMember.length > 0 ? listMember.map(req =>
                        <Box>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                {req?.name}
                            </Typography>
                            {dataInfo?.isAdmin &&
                                <div className='flex'>
                                    <Button onClick={() => handleRemoveUser(req?._id)}>Xóa khỏi nhóm</Button>
                                </div>}
                        </Box>
                    ) : <Box><Typography id="modal-modal-description" sx={{ mt: 2 }}>Không có thành viên khác</Typography></Box>}
                </Box>
            </Modal>
        </>
    )
}

export default GroupInfo