import React, { useEffect, useState } from "react";
import { useAppContext } from "../../context/useContext";

import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Left from "../dashboard/components/Weather.component";
import { useNavigate } from "react-router-dom";
import Right from "../dashboard/components/Sugestion.component";
import { TiGroup } from "react-icons/ti";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingGroup from "../loading/Loading.Group";

const Group = () => {
  const navigate = useNavigate();
  const { dark, autoFetch, setOneState, user, setNameAndToken, token } =
    useAppContext();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(false);

  const getAllPosts = async () => {
    setLoading(true);
    try {
      const { data } = await autoFetch.get(
        `/api/post/news-feed?page=1&perPage=5`
      );
      setPosts(data.posts);
    } catch (error) {
      console.log(error);
      setError(true);
    }
    setLoading(false);
  };
  const [showModal, setShowModal] = useState(false);
  const [disable, setDisable] = useState(false);
  const [state, setState] = useState({});
  const [perPage, setPerPage] = useState(15);

  const [groups, setGroups] = useState([]);

  const handleChangeInput = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    getNewGroups()
  }, [])
  

  const getNewGroups = async () => {
    try {
      const { data } = await autoFetch.get(
        `/api/group/all-group?page=${page + 1}&perPage=${perPage}&userID=${user._id}`
      );
      setPage(page + 1);
      // @ts-ignore
      setGroups([...groups, ...data.groups]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateGroup = async (formData) => {
    if (!state) {
      toast.error("You must type something...");
      return;
    }
    try {
      let image = null;
      setDisable(true)
      setLoading(true)
      if (formData) {
        const { data } = await autoFetch.post(
          `/api/group/create-group`,
          formData
        );
        image = { url: data.url, public_id: data.public_id };
        if(!data){
          toast.error("Error! Try again!");
        }
        data.group.isAdmin = true;
        setGroups([ data.group, ...groups])
        toast.success("Success!");
        setShowModal(false)
        setDisable(false)
        setLoading(false)
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong. Try again!");
      setLoading(false)
    }
  };

  const handleJoin = async (groupId) => {
    if (!groupId) {
      return false
    }
    try {
      const { data } = await autoFetch.post(
        `/api/group/join-group`, { groupId }
      )
      groups.find(e=>e._id === groupId).isRequest = true
      toast.success("Thành công")
      getNewGroups()
    } catch (error) {
      toast.error(error)
    }
  }

  return (
    <div className="overflow-x-hidden min-h-screen pt-16 md:pt-[85px]  ">
      <div className="w-screen grid grid-cols-11 md:gap-x-12 px-3 sm:px-7 md:px-10 relative ">
        <div className="col-span-11 md:col-span-3 relative order-1 ">
          <Left autoFetch={autoFetch} dark={dark} />
        </div>
        <div className="col-span-11 md:col-span-5 shrink-0 order-3 md:order-2  ">
          <div className="text-[24px] p-5 font-bold dark:bg-[#242526] bg-white mb-5 pt-3 rounded-lg px-2 md:px-4 shadow-post flex">
            <p>Các nhóm mà bạn có thể biết</p>
            <div className=" bg-[#ccc] ml-auto text-[14px] transition-50 cursor-pointer font-bold w-[130px] h-[35px] rounded-full hover:bg-[#F2F2F2] dark:hover:bg-[#3A3B3C] flex flex-row items-center justify-center group relative ">
              <button
                className="translate-y-[0px] z-[100] "
                onClick={() => setShowModal(true)}
              >
                Tạo nhóm mới +
              </button>
            </div>
          </div>
          <div className="infinite-scroll-component__outerdiv ">
            {showModal ? (
              <>
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                  <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                      {/*header*/}
                      <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                        <h3 className="text-3xl font-semibold">Tạo nhóm mới</h3>
                        <button
                          className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                          onClick={() => setShowModal(false)}
                        >
                          <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                            ×
                          </span>
                        </button>
                      </div>
                      {/*body*/}
                      <div className="relative p-6 flex-auto">
                        <div className="my-4 text-slate-500 text-lg leading-relaxed">
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                            }}
                            encType="multipart/form-data"
                          >
                            <div className="grid grid-cols-1 gap-x-2 md:gap-x-3 ">
                              <div className="col-span-1">
                                <div className="text-sm md:text-[16px] mb-1 md:mb-2">
                                  Tên nhóm
                                </div>
                                <input
                                  disabled={loading}
                                  type="text"
                                  className="input-register "
                                  placeholder="Nguyễn Văn A"
                                  name="name"
                                  onChange={(e) => handleChangeInput(e)}
                                />
                              </div>
                              <div className="col-span-1">
                                <div className="text-sm md:text-[16px] mt-1  mb-1 md:mb-2">
                                  Mô tả
                                </div>
                                <input
                                  disabled={loading}
                                  type="text"
                                  className=" input-register"
                                  placeholder="1712319656@student.humg.vn"
                                  name="about"
                                  onChange={(e) => handleChangeInput(e)}
                                />
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                      {/*footer*/}
                      <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                        <button
                          className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="button"
                          onClick={() => setShowModal(false)}
                        >
                          Close
                        </button>
                        <button
                          className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="submit"
                          onClick={() => handleCreateGroup(state)}
                          disabled={loading}
                        >
                          Tạo
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>{" "}
              </>
            ) : null}
            <InfiniteScroll
              dataLength={groups.length}
              next={getNewGroups}
              hasMore={true}
              loader={<LoadingGroup />}>
              {groups &&
                groups.map((e) => (
                  <div className="infinite-scroll-component " key={e._id}>
                    <div className="dark:bg-[#242526] bg-white mb-5 pt-3 pb-2.5 md:pb-3 rounded-lg shadow-post ">
                      <div className="flex items-center pl-2 pr-3 sm:px-3 md:px-4">
                        {e.image ? (
                          <img
                            src={`${e.image}`}
                            alt="avatar"
                            className="w-10 h-10 rounded-full object-cover cursor-pointer "
                          />
                        ) : (
                          <TiGroup size="50px" color="orange" />
                        )}
                        <div className="ml-2 font-bold ">
                          {(e.isMember || e.isAdmin) ?
                            <Link to={`./${e._id}`}>
                              <div className="flex items-center gap-x-1 cursor-pointer ">
                                {e.name}
                              </div>
                            </Link> : <div>
                              <div className="flex items-center gap-x-1 cursor-pointer ">
                                {e.name}
                              </div>
                            </div>}
                          <div className="font-[400] text-[13px] dark:text-[#B0B3B8] flex items-center gap-x-1 ">
                            {e.about}
                          </div>
                        </div>
                        {(!e.isMember && !e.isRequest && !e.isAdmin) && <div className="bg-[#ccc] ml-auto text-[14px] transition-50 cursor-pointer font-bold w-[70px] h-[35px] rounded-full hover:bg-[#F2F2F2] dark:hover:bg-[#3A3B3C] flex flex-row items-center justify-center group relative ">
                          <div className="translate-y-[0px] z-[100] " onClick={() => handleJoin(e._id)}>
                            Tham gia
                          </div>
                        </div>}
                        {e.isRequest &&
                          <div className="bg-[#ccc] ml-auto text-[14px] transition-50 cursor-pointer font-bold p-[4px] h-[35px] rounded-full hover:bg-[#F2F2F2] dark:hover:bg-[#3A3B3C] flex flex-row items-center justify-center group relative ">
                            <div className="translate-y-[0px] z-[100] ">
                              Đã yêu cầu tham gia
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                ))}
            </InfiniteScroll>
          </div>
        </div>
        <div className="col-span-11 md:col-span-3 relative order-2 md:order-3  ">
          <Right
            autoFetch={autoFetch}
            getAllPosts={getAllPosts}
            navigate={navigate}
            setNameAndToken={setNameAndToken}
            user={user}
            token={token}
            dark={dark}
            error={error}
          />
        </div>
      </div>
    </div>
  );
};

export default Group;
