import React from 'react'
import { useAppContext } from "../../context/useContext";

const LoadingGroup = ({ className = "" }) => {
    const { dark } = useAppContext();
    return (
        <div
            className={
                `w-full dark:bg-[#242526] bg-white rounded-lg p-4 ${!dark ? "shadow-post" : ""
                } mt-4 ` + className
            }>
            <div className='w-full h-[40vh] bg-loading rounded-lg my-5 '></div>
            <div className=' w-full py-5 rounded-lg bg-loading '></div>
        </div>
    )
}

export default LoadingGroup