import React, { useState } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../config/authConfig";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Nav } from "../components";
import { useAppContext } from "../context/useContext";
import { Navigate, NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import ReactLoading from "react-loading";
import { Button } from "@mui/material";

import chickenImg from "@/assets/images/chicken-bg.png"
import chicken from "@/assets/images/chicken.png"
import chickenDark from "@/assets/images/chicken-dark.png"

const Login = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const { dark, user, setNameAndToken, autoFetch } = useAppContext();
  const [eye, setEye] = useState(false);
  const [loading, setLoading] = useState(false);
  const initState = {
    email: "",
    password: "",
    rememberPassword: true,
  };

  const [state, setState] = useState(initState);

  const login = async () => {
    setLoading(true);
    try {
      const { password, rememberPassword } = state;
      const email = state.email.toLowerCase();
      const { data } = await autoFetch.post("/api/auth/login", {
        email,
        password,
        rememberPassword,
      });
      setNameAndToken(data.user, data.token);
      toast.success("Login success.");
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.msg || "Something went wrong!");
      setLoading(false);
    }
  };

  const handleChangeInput = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleLogin = (loginType) => {
    if (loginType === "popup") {
      instance
        .loginPopup(loginRequest)
        .then(async (res) => {
          var bodyFormData = new FormData();
          bodyFormData.append("username", "user@ms");
          bodyFormData.append("password", res.accessToken);
          bodyFormData.append("grant_type", "user@password");


          var loginForm = new FormData();

          try {
            const { data } = await autoFetch.post("/api/auth/oAuth", loginForm, {
              headers: {
                Authorization: `Bearer ${res.accessToken}`,
              }
            })
            if (data) {
              setNameAndToken(data.user, data.token);
              localStorage.setItem("ms", true)
              toast.success("Login success.");
              setLoading(false);
            }
            else toast.error("Không thành công")
          } catch (error) {
            toast.error("Có lỗi xảy ra")
          }

          // axios
          //   .get("https://graph.microsoft.com/v1.0/me", {
          //     headers: {
          //       Authorization: `Bearer ${res.accessToken}`,
          //     },
          //   })
          //   .then((rs) => console.log(rs))
          //   .catch((err) => console.log(err));

          // axios
          //   .get("https://graph.microsoft.com/v1.0/me/messages", {
          //     headers: {
          //       Authorization: `Bearer ${res.accessToken}`,
          //     },
          //   })
          //   .then((rs) => console.log(rs))
          //   .catch((err) => console.log(err));

          // axios
          //   .get("https://graph.microsoft.com/v1.0/me/drive", {
          //     headers: {
          //       Authorization: `Bearer ${res.accessToken}`,
          //     },
          //   })
          //   .then((rs) => console.log(rs))
          //   .catch((err) => console.log(err));

          // // axios.get("https://graph.microsoft.com/v1.0/groups", {
          // //     headers: {
          // //         'Authorization': `Bearer ${res.accessToken}`
          // //     }
          // // }).then(rs => console.log(rs)).catch(err => console.log(err))

          // axios
          //   .get("https://management.azure.com/tenants", {
          //     headers: {
          //       Authorization: `Bearer ${res.accessToken}`,
          //     },
          //   })
          //   .then((rs) => console.log(rs))
          //   .catch((err) => console.log(err));

          console.log(res);
        })
        .catch((e) => {
          console.log(e);
        });
    } else if (loginType === "redirect") {
      instance
        .loginRedirect(loginRequest)
        .then((res) => console.log(res.account))
        .catch((e) => {
          console.log(e);
        });
    }
  };

  if (user) {
    return <Navigate to="/" />;
  }

  return (
    <div>
      <Nav />
      <div className="pt-12 bg-[#FEDCC5] dark:bg-[#4E4F50] h-screen w-screen flex items-center relative transition-50 ">
        {/* image chicken */}
        <div className="fixed z-0 bottom-0">
          <img
            src={chickenImg}
            alt="bg"
            className="h-[100vh] w-auto object-cover "
          />
        </div>
        {/* form */}
        <div className="w-full md:w-[80%] mx-auto flex items-center justify-center  md:justify-between z-[1] md:mt-4 ">
          <div className="bg-[#FEE7D6] dark:bg-[#3a3a3a] dark:text-white/70 w-[90%] md:w-auto px-[20px] md:px-[80px] py-[30px] md:py-[40px] rounded-3xl ">
            <div className=" mb-[18px] ">
              {/* <img
                                src={`/images/${
                                    dark ? "login-dark.png" : "login.png"
                                }`}
                                alt='login'
                                className='h-[40px] w-auto md:h-[50px] '
                            /> */}
              <div className="text-sm md:text-[32px] mb-2 font-bold">
                Đăng nhập
              </div>
            </div>
            <form
              className="md:mt-[20px] "
              onSubmit={(e) => {
                e.preventDefault();
                login();
              }}
            >
              <div className="">
                <div className="text-sm md:text-[16px] mb-2">Email</div>
                <input
                  type="email"
                  className="input-login "
                  placeholder="User@gmail.com"
                  name="email"
                  onChange={(e) => handleChangeInput(e)}
                  disabled={loading}
                />
              </div>
              <div className="mt-[25px]">
                <div className="text-sm md:text-[16px] mb-2">Mật khẩu</div>
                <div className="flex items-center relative">
                  <input
                    type={eye ? "text" : "password"}
                    className=" input-login"
                    placeholder="Mật khẩu"
                    name="password"
                    onChange={(e) => handleChangeInput(e)}
                    disabled={loading}
                  />
                  {eye ? (
                    <AiOutlineEye
                      className="text-black/20 text-[20px] absolute right-2 cursor-pointer h-full dark:text-white/40"
                      onClick={() => setEye(!eye)}
                    />
                  ) : (
                    <AiOutlineEyeInvisible
                      className="text-black/20 text-[20px] absolute right-2 cursor-pointer h-full dark:text-white/40"
                      onClick={() => setEye(!eye)}
                    />
                  )}
                </div>
              </div>
              <div className="mt-[12px] md:mt-[17px] text-[13px] cursor-pointer font-normal flex justify-between items-center ">
                <NavLink to="/forget-password">Quên mật khẩu</NavLink>
                <span>
                  Ghi nhớ tài khoản{" "}
                  <input
                    type="checkbox"
                    defaultChecked
                    className="rounded-[4px] ring-[#F25019] checked:bg-[#F25019]"
                    value={String(state.rememberPassword)}
                    onChange={(e) => {
                      setState({
                        ...state,
                        rememberPassword: e.target.value === "true",
                      });
                    }}
                    disabled={loading}
                  />
                </span>
              </div>
              <button
                className={`mt-[35px] md:mt-[35px] w-full font-extrabold text-[20px] md:text-2xl bg-[#F25019] text-white py-[8px] md:py-[13px] rounded-[5px] ${loading ? "loading" : ""
                  } flex items-center justify-center `}
                type="submit"
                disabled={loading}
              >
                {loading ? (
                  <ReactLoading
                    type="bubbles"
                    width={32}
                    height={32}
                    color="white"
                  />
                ) : (
                  "Đăng nhập"
                )}
              </button>
            </form>
            <div className="mt-[15px] md:mt-[30px] font-normal text-[13px] text-center ">
              hoặc tiếp tục với
            </div>
            <div className="mt-[8px] md:mt-[16px] flex items-center justify-between  gap-x-[11px] shrink-1 ">
              <div className="icon-login " role="button" onClick={() => handleLogin("popup")}>
                <Button>Login with MS Account</Button>
              </div>
            </div>
            <div className="mt-[8px] md:mt-[16px] text-[13px] md:text-[15px] text-center ">
              <span className="block md:inline ">Chưa có tài khoản? </span>
              <NavLink
                to={"/register"}
                role="button"
                className="hover:scale-110 text-[18px] font-bold "
              >
                Đăng ký
              </NavLink>
            </div>
          </div>
          <img
            src={dark ? chicken : chickenDark}
            alt="chicken"
            className="w-[50%] h-auto object-cover hidden md:inline "
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
