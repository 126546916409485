import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Modal, Post, LoadingPost, LoadingForm, FormCreatePost } from "../..";
import InfiniteScroll from "react-infinite-scroll-component";
import GroupInfo from "@/components/common/GroupInfo";
import GroupPost from "@/components/common/GroupPost";

const GroupDetail = ({
    groupPosts,
    loading,
    token,
    autoFetch,
    setCurrentState,
    dark,
    user,
    setGroupPosts,
    getNewGroupPostPosts,
    error,
    isQrCode,
}) => {
    const [attachment, setAttachment] = useState("");
    const [text, setText] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const [loadingCreateNewPost, setLoadingCreateNewPost] = useState(false);
    const [groupInfo, setGroupInfo] = useState({})

    // Modal
    useEffect(() => {
        setCurrentState("openModal", openModal);
    }, [openModal]);

    useEffect(() => {
        getGroupInfo()
    }, [])

    const getGroupInfo = async () => {
        try {
            const { data } = await autoFetch.get(`/api/group/detail?groupId=${window.location.pathname.split('/')[2]}&uid=${user._id}`);
            setGroupInfo(data);
        } catch (error) {
            console.log(error);
        }
    }

    const createNewPost = async (formData) => {
        setLoadingCreateNewPost(true);
        if (!text) {
            toast.error("You must type something...");
            return;
        }
        try {
            let image = null;
            if (formData) {
                const { data } = await autoFetch.post(
                    `/api/group/uploadImage`,
                    formData
                );
                image = { url: data.url, public_id: data.public_id };
            }

            const { data } = await autoFetch.post(`/api/group/create-post`, {
                content: text,
                image,
                groupId: window.location.pathname.split('/')[2]
            });
            setGroupPosts([data.groupPosts, ...groupPosts]);
        } catch (error) {
            console.log(error);
        }
        setLoadingCreateNewPost(false);
    };

    const content = () => {
        if (loading) {
            return (
                <div>
                    <LoadingPost />
                </div>
            );
        }
        if (error) {
            return (
                <div
                    className={`bg-white ${!dark && "shadow-post"
                        } dark:bg-[#242526] rounded-lg w-full text-center text-xl font-bold py-10 `}>
                    <div>Chưa có bài đăng... Try again!</div>
                </div>
            );
        }
        if (groupPosts.length === 0) {
            return (
                <div className='w-full text-center text-xl font-semibold pt-[20vh] flex-col '>
                    <div>
                        You don't post anything and don't follow anyone.
                        <br />
                        Let's do something! :3
                    </div>
                </div>
            );
        }
        return (
            <InfiniteScroll
                dataLength={groupPosts.length || 0}
                next={getNewGroupPostPosts}
                hasMore={true}
                loader={<LoadingPost />}>
                {groupPosts.map((post) => (
                    <GroupPost
                        key={post._id}
                        currentPost={post}
                        user_img={user.image.url}
                        userId={user._id}
                        className={!dark ? "shadow-post" : ""}
                        userRole={user.role}
                    />
                ))}
            </InfiniteScroll>
        );
    };

    const form = () => {
        if (error) {
            return <></>;
        }
        if (loading) return <LoadingForm />;
        return (
            <>
                <GroupInfo dataInfo={groupInfo.group} key={groupInfo._id}/>
                <FormCreatePost
                    setAttachment={setAttachment}
                    setOpenModal={setOpenModal}
                    text={text}
                    user={user}
                />
            </>
        );
    };

    return (
        <div className=''>
            {form()}

            {openModal && !isQrCode && (
                <Modal
                    setOpenModal={setOpenModal}
                    text={text}
                    setText={setText}
                    attachment={attachment}
                    setAttachment={setAttachment}
                    createNewPost={createNewPost}
                />
            )}
            {loadingCreateNewPost && <LoadingPost className='mb-4' />}
            {content()}
        </div>
    );
};

export default GroupDetail;
