import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useAppContext } from "./context/useContext.jsx";
import { ContextProvider } from "./context/SocketContext";
import { ToastContainer } from "react-toastify";
// page
import {
  Home,
  Login,
  Register,
  ForgetPassword,
  Error,
  ProtectedLayout,
  ShareLayout,
} from "./page";
// layout
import { Dashboard, Messenger, Admin } from "./page/Layout";
import { Profile, UpdateProfile } from "./page/user";
import { Information } from "./page/Post";
// modal qrCode
import { Group, ModalQrCode } from "./components";
import GroupDetail from "./components/dashboard/components/GroupDetail.component";
import Calling from "./components/calling/Calling";

const App = () => {
  const { dark, openModal, isQrCode } = useAppContext();
  return (
    <div className={`${dark ? "dark" : ""} relative `}>
      {/* Notification */}
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme={dark ? "dark" : "light"}
      />
      {/*QR code*/}
      {openModal && isQrCode && <ModalQrCode />}
      {/* Router */}
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedLayout>
                <ShareLayout />
              </ProtectedLayout>
            }
          >
            <Route
              // @ts-ignore
              index
              path="/"
              element={<Dashboard />}
            />
            <Route path="/messenger" element={
              <Messenger />
            } />

            <Route
              exact
              path="/calling"
              element={
                <ContextProvider><Calling /></ContextProvider>
              }
            />

            <Route path="/admin" element={<Admin />} />
            <Route path="/profile/*" element={<Profile />} />
            <Route path="/group/" element={<Group />} />
            <Route exact path="/group/:id" element={<Dashboard />} />
            <Route path="/update-profile" element={<UpdateProfile />} />
            <Route path="/post/information/:id" element={<Information />} />
          </Route>

          <Route exact path="/home" element={<Home />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/register" element={<Register />} />

          <Route exact path="/forget-password" element={<ForgetPassword />} />

          <Route exact path="*" element={<Error />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
