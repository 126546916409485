import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AppProvider } from "./context/useContext.jsx";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./config/authConfig";
import "./index.css";
import "./font.css";
import "react-toastify/dist/ReactToastify.css";
// import "./fonts/PressStart2P.tff";
// import "./fonts/Quicksand.tff";

const root = ReactDOM.createRoot(document.getElementById("root"));
const msalInstance = new PublicClientApplication(msalConfig);

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <AppProvider>
          <App />
      </AppProvider>
    </MsalProvider>
  </React.StrictMode>
);
